import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'; // Assuming you have a custom CSS file
import SelectLang from './SelectLang';
import useLanguage from '../languages/useLanguage';
import { useNavigate } from 'react-router-dom';

const FeedbackMessage = () => {
  const langs = useLanguage();
  const lang = langs.feedbackMessage;
  const navigate = useNavigate();

  // Handle back button press
  useEffect(() => {
    const handlePopState = () => {
      navigate('/'); // Replace '/different-page' with your desired route
    };

    // Push the current path to the history stack
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', handlePopState);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  return (
    <div>
      {/* Header Section */}
      <section className="headerBox blue-bg">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 pt-2 pb-2 top">
                <a href="#">
                  <img src="images/logo.png" alt="Canara Bank Logo" />
                </a>
              </div>
            </div>
          </div>
          <div className="container-fluid langCol">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 bottom pt-1 pb-1 top">
                  <SelectLang />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Thank You Section */}
      <section className="thankBox">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 inner text-center">
                <img src="images/thank-you.png" alt="Thank You" className="img-fluid" />
                <p style={{ color: 'red' }}>
                  {lang.line1} <br />
                  {lang.line2}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footerBox mt-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 p-0">
                <p>@2024, Canara Bank, All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FeedbackMessage;
