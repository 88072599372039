const punjabi = {
    feedbackServiceFlow: {
        line1: "ਜਨਰਲ ਫੀਡਬੈਕ",
        line2: "ਸੇਵਾ ਦੇ ਸਤ੍ਹੇ ਦਾ ਫੀਡਬੈਕ"
    },
    mobile: {
        line1: "ਆਪਣਾ ਮੋਬਾਈਲ ਨੰਬਰ ਦਿਓ",
        line2: "ਅਸੀਂ ਤੁਹਾਨੂੰ ਇੱਕ ਵੈਰੀਫਿਕੇਸ਼ਨ ਕੋਡ ਭੇਜਾਂਗੇ",
        line3: "ਮੋਬਾਈਲ ਨੰਬਰ ਦਿਓ",
        line4: "ਜਾਰੀ ਰੱਖੋ",
        error: {
            error: "ਇੱਕ ਗਲਤੀ ਹੋ ਗਈ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਫਿਰ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
            otp: "OTP ਭੇਜਣ ਵਿੱਚ ਅਸਫਲ। ਕਿਰਪਾ ਕਰਕੇ ਫਿਰ ਕੋਸ਼ਿਸ਼ ਕਰੋ।"
        }
    },
    otp: {
        line1: "ਵੈਰੀਫਿਕੇਸ਼ਨ ਕੋਡ",
        line2: "ਅਸੀਂ ਤੁਹਾਡੇ ਮੋਬਾਈਲ ਨੰਬਰ ਤੇ ਵੈਰੀਫਿਕੇਸ਼ਨ ਕੋਡ ਭੇਜਿਆ ਹੈ",
        line3: "OTP ਨਹੀਂ ਮਿਲਿਆ?",
        line4: "ਜਾਰੀ ਰੱਖੋ",
        line5: "ਫੀਡਬੈਕ ਲਈ ਸੇਵਾ ਦੀ ਕਿਸਮ ਚੁਣੋ",
        line6: "ਨਕਦ ਜਮ੍ਹਾ",
        line7: "ਫੰਡ ਟ੍ਰਾਂਸਫਰ",
        line8: "ਖਾਤਾ ਖੋਲ੍ਹਣਾ",
        line9: "SB ਖਾਤਾ ਸਬੰਧਤ ਸੇਵਾਵਾਂ",
        line10: "ਕਾਰੰਟ ਖਾਤਾ",
        line11: "ਰੱਦ ਕਰੋ",
        line12: "ਅਗਲਾ",
        line13: "OTP ਦੁਬਾਰਾ ਭੇਜੋ",
        line14: "OTP ਸਫਲਤਾਪੂਰਕ ਭੇਜਿਆ ਗਿਆ",
        noTransactionTypes: "ਕਿਸੇ ਵੀ ਸੇਵਾ ਦੀ ਵਰਤੋਂ ਤੋਂ ਬਿਨਾਂ ਤੁਸੀਂ ਫੀਡਬੈਕ ਨਹੀਂ ਦੇ ਸਕਦੇ",
        error: {
            error: "OTP ਭੇਜਣ ਵਿੱਚ ਗਲਤੀ ਆਈ। ਕਿਰਪਾ ਕਰਕੇ ਫਿਰ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
            otp: "ਅਵੈਧ OTP"
        }
    },
    feedback: {
        line1: "ਅੱਜ ਸਾਡੇ ਨਾਲ ਤੁਹਾਡੇ ਅਨੁਭਵ ਦੀ ਮੂਲਿਆੰਕਣ ਕਰੋ",
        verypoor: "ਬਹੁਤ ਖਰਾਬ",
        belowAverage: "ਸਧਾਰਨ ਤੋਂ ਥੱਲੇ",
        average: "ਸਧਾਰਨ",
        good: "ਚੰਗਾ",
        veryGood: "ਬਹੁਤ ਚੰਗਾ",
        excellent: "ਸ਼ਾਨਦਾਰ",
        outstanding: "ਬਹੁਤ ਉਤਕ੍ਰਿਸ਼ਟ",
        poorFeedbackHeader: "ਸਾਨੂੰ ਅਫਸੋਸ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਅਸੰਤੋਸ਼ ਦੇ ਕਾਰਣ ਨੂੰ ਵਿਸਥਾਰ ਨਾਲ ਦੱਸੋ:",
        badService: "ਬੁਰਾ ਸੇਵਾ",
        notGoodSupport: "ਗਾਹਕ ਸਹਾਇਤਾ ਚੰਗੀ ਨਹੀਂ",
        others: "ਹੋਰ",
        textareaPlaceholder: "ਸਾਨੂੰ ਅਫਸੋਸ ਹੈ",
        captchaPlaceholder: "ਕੈਪਚਾ ਲਿਖੋ",
        reload: "ਕੈਪਚਾ ਦੁਬਾਰਾ ਲੋਡ ਕਰੋ",
        submit: "ਸਬਮਿਟ ਕਰੋ",
        work: "ਕਾਮ ਸਮੇਂ 'ਤੇ ਮੁਕੰਮਲ ਨਹੀਂ ਹੋਇਆ",
        inadequate: "ਸਟਾਫ ਦੀ ਉਤਪਾਦ/ਸੇਵਾ ਬਾਰੇ ਘੱਟ ਜਾਣਕਾਰੀ",
        guidance: "ਕਾਮ ਕਰਨ ਲਈ ਸਹੀ ਦਿਸ਼ਾ-ਨਿਰਦੇਸ਼ ਨਾ ਦੇਣਾ",
        staff: "ਸਟਾਫ ਦਾ ਵਿਵਹਾਰ",
        products: "ਉਤਪਾਦ/ਪ੍ਰਕਿਰਿਆ ਗਾਹਕਾਂ ਦੀ ਲੋੜਾਂ ਨੂੰ ਪੂਰਾ ਕਰਨ ਲਈ ਸਹੀ ਨਹੀਂ",
        other: "ਹੋਰ",
        general1: "ਕੰਮ ਸੰਤੁਸ਼ਟਿਕਰ ਤਰੀਕੇ ਨਾਲ ਪੂਰਾ ਨਹੀਂ ਕੀਤਾ ਗਿਆ",
general2: "ਉਤਪਾਦ/ਸੇਵਾ ਬਾਰੇ ਸਟਾਫ ਦੀ ਅਧੂਰੀ ਜਾਣਕਾਰੀ",
general3: "ਕੰਮ ਕਰਨ ਲਈ ਕੋਈ ਸਹੀ ਮਾਰਗਦਰਸ਼ਨ ਨਹੀਂ ਦਿੱਤਾ ਗਿਆ",
general4: "ਸਟਾਫ ਦਾ ਵਿਆਹਾਰ",
general5: "ਉਤਪਾਦ/ਪ੍ਰਕਿਰਿਆ ਗ੍ਰਾਹਕਾਂ ਦੀਆਂ ਜ਼ਰੂਰਤਾਂ ਨੂੰ ਪੂਰਾ ਕਰਨ ਲਈ ਉਚਿਤ ਨਹੀਂ ਹੈ",
general6: "ਹੋਰ ਕੁਝ",
        error: {
            selectRating: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਰੇਟਿੰਗ ਚੁਣੋ।",
            incorrectCaptcha: "ਕੈਪਚਾ ਗਲਤ ਹੈ।",
            submitFeedback: "ਆਪਣੇ ਪਛਾਣ ਬਿਨਾਂ ਫੀਡਬੈਕ ਨਹੀਂ ਦੇ ਸਕਦੇ",
            twice: "ਤੁਸੀਂ ਦੋ ਵਾਰ ਫੀਡਬੈਕ ਨਹੀਂ ਦੇ ਸਕਦੇ",
            transaction: "ਕੋਈ ਲੈਣ-ਦੇਣ ਤੋਂ ਬਿਨਾਂ ਫੀਡਬੈਕ ਨਹੀਂ ਦੇ ਸਕਦੇ"
        }
    },
    thankYou: {
        line1: "ਫੀਡਬੈਕ ਦੇਣ ਲਈ ਧੰਨਵਾਦ,",
        line2: "ਅਸੀਂ ਤੁਹਾਡੇ ਜਵਾਬ ਦੀ ਕਦਰ ਕਰਦੇ ਹਾਂ"
    },
    feedbackMessage: {
        line1: "ਅਸੀਂ ਪਹਿਲਾਂ ਹੀ ਤੁਹਾਡਾ ਫੀਡਬੈਕ ਪ੍ਰਾਪਤ ਕਰ ਚੁੱਕੇ ਹਾਂ",
        line2: "ਤੁਸੀਂ ਦੋ ਵਾਰ ਸਬਮਿਟ ਨਹੀਂ ਕਰ ਸਕਦੇ"
    },
    qr :"ପ୍ରିୟ ଗ୍ରାହକ, ଆପଣଙ୍କର ମତାମତ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ! ଆମକୁ ଭଲ ଭାବରେ ସେବା କରିବାରେ ସାହାଯ୍ୟ କରିବାକୁ ଶାଖା ବ୍ୟାଙ୍କିଙ୍ଗ ଅଭିଜ୍ଞତା ଉପରେ ଦୟାକରି ଆପଣଙ୍କର ମତାମତ ଦାଖଲ କରନ୍ତୁ"
};

export default punjabi;
