import { BrowserRouter,Routes, Route } from 'react-router-dom';
import Mobile from './components/Mobile';
import Feedback from './components/Feedback';
import Otp from './components/Otp';
import QR from './components/QR';
import ThankYou from './components/ThankYou';
import EmployeeCode from './components/employeeCode';
import SalesforceLogin from './components/salesForceLogin';
import FeedbackServiceFlow from './components/FeedbackServiceFlow';
import { Provider } from 'react-redux';
import FeedbackMessage from './components/FeedbackMessage';
import store from './store';
import { ToastContainer} from 'react-toastify';
import FeedbackMessageService from './components/FeedbackMessageService';

function App() {
  return (
    <Provider store={store}>
      <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                limit={1}
                />

               <ToastContainer />
    <BrowserRouter>
    <Routes>
    <Route exact path="/" element={<FeedbackServiceFlow />} />
    <Route path= "/phone-number" element={<Mobile/>}/>  
    <Route path="/feedback" element={<Feedback/>} />
    <Route path="/otp-verification" element={<Otp/>} />
    <Route path="/QR" element={<QR/>} />
    <Route path="/thank-you" element={<ThankYou/>} />
    <Route path="/employee-code" element={<EmployeeCode/>} />
    <Route path="/employee-feedback" element={<SalesforceLogin/>} />
    <Route path="/feedback-response" element={<FeedbackMessage/>} />
    <Route path="/feedback-response-service" element={<FeedbackMessageService/>} />
    </Routes>
  </BrowserRouter>
  </Provider>
  );
}

export default App;
