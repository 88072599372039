const tamil = {
    feedbackServiceFlow: {
        line1: "பொது கருத்து",
        line2: "சேவை நிலை கருத்து"
    },
    mobile: {
        line1: "உங்கள் கைபேசி எண்ணை உள்ளிடவும்",
        line2: "நாங்கள் உங்களுக்கு சரிபார்ப்பு குறியீட்டை அனுப்புவோம்",
        line3: "கைபேசி எண் உள்ளிடவும்",
        line4: "தொடர்க",
        error: {
            error: "ஒரு பிழை ஏற்பட்டது. தயவுசெய்து பின்னர் முயற்சிக்கவும்",
            otp: "OTP அனுப்ப முடியவில்லை. தயவுசெய்து மீண்டும் முயற்சிக்கவும்."
        }
    },
    otp: {
        line1: "சரிபார்ப்பு குறியீடு",
        line2: "நாங்கள் உங்கள் கைபேசி எண்ணுக்கு சரிபார்ப்பு குறியீட்டை அனுப்பியுள்ளோம்",
        line3: "OTP கிடைக்கவில்லையா?",
        line4: "தொடர்க",
        line5: "கருத்துக்காக சேவை வகையைத் தேர்ந்தெடுக்கவும்",
        line6: "பண வைப்பு",
        line7: "நிதி பரிமாற்றம்",
        line8: "கணக்கு தொடங்குதல்",
        line9: "SB கணக்குக்கு தொடர்புடைய சேவைகள்",
        line10: "நடப்பு கணக்கு",
        line11: "ரத்துசெய்",
        line12: "அடுத்து",
        line13: "OTP மறுபரிசீலனை",
        line14: "OTP வெற்றிகரமாக அனுப்பப்பட்டது",
        noTransactionTypes: "எந்த சேவையையும் பயன்படுத்தாமல் நீங்கள் கருத்து வழங்க முடியாது",
        error: {
            error: "OTP அனுப்பும் போது பிழை ஏற்பட்டது. தயவுசெய்து பின்னர் முயற்சிக்கவும்",
            otp: "செல்லுபடியாகாத OTP"
        }
    },
    feedback: {
        line1: "இன்று நம்முடன் இருக்கும் உங்கள் அனுபவத்தை மதிப்பிடவும்",
        verypoor: "மிக மோசமானது",
        poor: "மோசமானது",
        average: "சராசரி",
        good: "நல்லது",
        veryGood: "மிக நல்லது",
        outstanding: "மிக சிறந்தது",
        poorFeedbackHeader: "நாங்கள் வருந்துகிறோம், தயவுசெய்து அதற்கான காரணத்தை விரிவாக கூறுங்கள்:",
        badService: "மோசமான சேவை",
        notGoodSupport: "வாடிக்கையாளர் ஆதரவு நல்லதல்ல",
        others: "மற்றவை",
        textareaPlaceholder: "நாங்கள் வருந்துகிறோம்",
        captchaPlaceholder: "கேப்ட்சாவை உள்ளிடவும்",
        reload: "கேப்ட்சா மீளமைக்க",
        submit: "சமர்ப்பிக்கவும்",
        work: "வேலை நேரத்தில் முடிக்கப்படவில்லை",
        inadequate: "தயாரிப்புகளின்/சேவைகளின் சரியான அறிவில்லை",
        guidance: "வேலை முடிக்க வழிகாட்டுதல் சரியாக வழங்கப்படவில்லை",
        staff: "பணியாளர் நடத்தை",
        products: "தயாரிப்புகள்/செயல்முறைகள் வாடிக்கையாளர்களின் தேவைகளை பூர்த்தி செய்ய உதவுவதில்லை",
        other: "வேறு ஏதேனும்",
        general1: "பணி திருப்திகரமாக முடிக்கப்படவில்லை",
general2: "தயாரிப்பு/சேவையின் மீதான பணியாளர்களின் போதிய அறிவியல் இல்லை",
general3: "பணியை முடிக்க சரியான வழிகாட்டல் கொடுக்கப்படவில்லை",
general4: "பணியாளர்களின் நடத்தை",
general5: "தயாரிப்பு/செயல்முறை வாடிக்கையாளர் தேவைகளை பூர்த்தி செய்ய பொருத்தமானதாக இல்லை",
general6: "வேறு ஏதாவது",
        error: {
            selectRating: "தயவுசெய்து மதிப்பீட்டைத் தேர்ந்தெடுக்கவும்.",
            incorrectCaptcha: "கேப்ட்சா தவறானது.",
            submitFeedback: "அங்கீகாரம் இல்லாமல் நீங்கள் கருத்து அளிக்க முடியாது",
            twice: "நீங்கள் இரண்டாவது முறையாக கருத்து அளிக்க முடியாது",
            transaction: "நீங்கள் எந்த பரிவர்த்தனையும் செய்யாமல் கருத்து அளிக்க முடியாது"
        }
    },
    thankYou: {
        line1: "கருத்துக்கு நன்றி,",
        line2: "உங்கள் பதிலை நாங்கள் மதிக்கிறோம்"
    },
    feedbackMessage: {
        line1: "உங்கள் கருத்தை நாங்கள் ஏற்கனவே பெற்றுள்ளோம்",
        line2: "நீங்கள் இரண்டாவது முறையாக சமர்ப்பிக்க முடியாது"
    },
    employee: {
        line1: "பணியாளர் குறியீட்டை உள்ளிடவும்",
        line2: "உங்கள் பணியாளர் குறியீட்டை உள்ளிடவும்",
        line3: "பணியாளர் குறியீடு",
        line4: "சமர்ப்பிக்கவும்",
        error: {
            error1: "பணியாளர் குறியீடு காலியாக இருக்கக்கூடாது",
            error2: "இந்த அடையாளத்துடன் பணியாளரை கண்டறிய முடியவில்லை",
            error3: "உள்நுழைவு விவரங்களை பெற முடியவில்லை"
        }
    },
    salesforce: {
        line1: "சேல்ஸ்போர்ஸுடன் உள்நுழைக",
        line2: "சேல்ஸ்போர்ஸுடன் உள்நுழைக"
    },
    qr : "அன்புள்ள வாடிக்கையாளர்களே, உங்கள் கருத்து முக்கியமானது! உங்களுக்குச் சிறப்பாகச் சேவை செய்ய எங்களுக்கு உதவ, கிளை வங்கி அனுபவத்தைப் பற்றிய உங்கள் கருத்தைச் சமர்ப்பிக்கவும்"
}

export default tamil;
