// import React, { useRef } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './style.css'; 
// import QRCode from 'react-qr-code';
// import SelectLangQR from './SelectLangQR';
// import useLanguage from '../languages/useLanguage';
// import './QR.css';
// import { toPng } from 'html-to-image';
// import { saveAs } from 'file-saver';

// const QR = () => {
//   const langs = useLanguage();
//   const lang = langs.qr;
//   const empCode = localStorage.getItem('employeeCode');
//   const empName = localStorage.getItem('employeeName');

//   // Ref for the QR section (including header but excluding SelectLangQR)
//   const downloadRef = useRef(null);

//   // Function to handle download as image
//   const handleDownloadImage = () => {
//     if (downloadRef.current === null) {
//       return;
//     }

//     // Temporarily hide SelectLangQR and download button for the download
//     const langComponent = document.querySelector('.langCol');
//     const downloadButton = document.querySelector('.download-button');
//     if (langComponent) langComponent.style.display = 'none';
//     if (downloadButton) downloadButton.style.display = 'none';

//     // Generate the image
//     toPng(downloadRef.current, { cacheBust: true })
//       .then((dataUrl) => {
//         saveAs(dataUrl, 'QRCodePage.png'); // Save the image with a desired name
//       })
//       .catch((err) => {
//         console.error('Failed to download image:', err);
//       })
//       .finally(() => {
//         // Restore SelectLangQR and download button after download
//         if (langComponent) langComponent.style.display = '';
//         if (downloadButton) downloadButton.style.display = '';
//       });
//   };

//   return (
//     <div>
//       {/* Main Download Section including header but not SelectLangQR */}
//       <div ref={downloadRef}>
//         <section className="headerBox">
//           <div className="centered-fluid">
//             <div className="centered">
//               <div className="row">
//                 <div className="col-lg-12 pt-2 pb-2 top blue-bg">
//                   <a href="#">
//                     <img src="images/logo.png" alt="Canara Bank Logo" width="242px" />
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>

//         <section className="headerBox">
//           <div className="centered-fluid">
//             <div className="centered">
//               <div className="row">
//                 {/* This is the SelectLangQR component which should be excluded during download */}
//                 <div className="col-lg-12 bottom pt-1 pb-1 top langCol">
                  
//                   <SelectLangQR />
//                   <button
//                     className="btn btn-link p-0 download-button"
//                     onClick={handleDownloadImage}
//                     style={{ border: 'none', background: 'transparent' }}
//                   >
//                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//   <path d="M12 2V16M12 16L8 12M12 16L16 12M4 18H20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// </svg>


//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>

//         <section className="qrBox">
//           <div className="centered-fluid">
//             <div className="centered">
//               <div className="row">
//                 <div className="inner pb-0 pt-4 inner2">
//                   <div className="col-sm-12">
//                     <div className="col-sm-12 qrText whiteBg">
//                       <p>
//                         Dear Customers, Your feedback matters! Kindly submit your feedback on branch banking experience to help us serve you better.
//                       </p>
//                       <hr />
//                       <p>
//                         {lang}
//                       </p>
//                     </div>
//                   </div>

//                   <div className="d-flex mt-4 pt-2" style={{ alignItems: 'center', justifyContent: 'space-around' }}>
//                     <div style={{ height: "auto", margin: "0 auto", maxWidth: "50%", width: "100%" }}>
//                       <QRCode
//                         size={256}
//                         style={{ height: "auto", maxWidth: "100%", width: "100%" }}
//                         value={`${process.env.REACT_APP_WEB_BASE_URL}phone-number?emp_code=${empCode}&emp_name=${empName}`}
//                         viewBox={`0 0 256 256`}
//                       />
//                     </div>
//                     <img src="images/qr2.png" alt="Side Image" className="img-fluid" style={{ maxWidth: '50%' }} />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>

//       {/* Footer Section */}
//       <footer className="footerBox p-0" style={{ background: 'none' }}>
//         <div className="centered-fluid">
//           <div className="centered">
//             <div className="row">
//               <div className="col-lg-12 footercol">
//                 <a href="#" className="call">
//                   <img src="images/call.png" alt="" style={{ marginRight: '10px' }} /> 9076030001
//                 </a>
//                 <ul>
//                   <li><a href="#"><img src="images/fb.svg" alt="Facebook" width="24px" /></a></li>
//                   <li><a href="#"><img src="images/insta.svg" alt="Instagram" width="24px" /></a></li>
//                   <li><a href="#"><img src="images/linkedin.svg" alt="LinkedIn" width="24px" /></a></li>
//                   <li><a href="#"><img src="images/youtube.svg" alt="YouTube" width="24px" /></a></li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </footer>
//     </div>
//   );
// };

// export default QR;


import React, { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'; 
import QRCode from 'react-qr-code';
import SelectLangQR from './SelectLangQR';
import useLanguage from '../languages/useLanguage';
import './QR.css';
import { toPng } from 'html-to-image';
import { saveAs } from 'file-saver';

const QR = () => {
  const langs = useLanguage();
  const lang = langs.qr;
  const empCode = localStorage.getItem('employeeCode');
  const empName = localStorage.getItem('employeeName');

  // Ref for the QR section (excluding SelectLangQR)
  const downloadRef = useRef(null);

  // Function to handle download as image
  const handleDownloadImage = () => {
    if (downloadRef.current === null) {
      return;
    }

    // Temporarily hide SelectLangQR and download button for the download
    const langComponent = document.querySelector('.langCol');
    const downloadButton = document.querySelector('.download-button');
    if (langComponent) langComponent.style.display = 'none';
    if (downloadButton) downloadButton.style.display = 'none';

    // Generate the image
    toPng(downloadRef.current, { cacheBust: true })
      .then((dataUrl) => {
        saveAs(dataUrl, 'QRCodePage.png'); // Save the image with a desired name
      })
      .catch((err) => {
        console.error('Failed to download image:', err);
      })
      .finally(() => {
        // Restore SelectLangQR and download button after download
        if (langComponent) langComponent.style.display = '';
        if (downloadButton) downloadButton.style.display = '';
      });
  };

  return (
    <div>
      {/* Main Download Section excluding SelectLangQR */}
      
      <section className="qrPage">
        
        <div className="container-fluid" >
        <section className="headerBox">
        <div className="centered-fluid">
          <div className="centered">
            <div className="row">
              <div className="col-lg-12 bottom pt-1 pb-1 top langCol">
                <SelectLangQR />
                <button
                  className="btn btn-link p-0 download-button"
                  onClick={handleDownloadImage}
                  style={{ border: 'none', background: 'transparent' }}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2V16M12 16L8 12M12 16L16 12M4 18H20" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
          
          <div className="centered" ref={downloadRef}>
            
            <div className="row inner">
              
              <div className="col-sm-12">
                <a href="#" className="logo"><img src="images/logo.png" alt="Logo" width="200px" /></a>
              </div>
              
              <div className="col-sm-12 pBox">

                <p className="qouteIcon">
                <p >
                  {lang}
                </p>
                  Dear Customers, Your feedback matters! Kindly submit your feedback on branch banking experience to help us serve you better.
                </p>
              </div>

              <div className="col-sm-5 qrGirl">
                <img src="images/qr_girl.png" alt="QR Girl" />
              </div>
              <div className="col-sm-7 qrCode">
                  <div className="qr-wrapper">
                    <QRCode
                      size={256} /* Increase size for clarity */
                      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                      value={`${process.env.REACT_APP_WEB_BASE_URL}phone-number?emp_code=${empCode}&emp_name=${empName}`}
                    />
                    <div className="blue-label">
                      <span>Your feedback matters</span><br />
                      <span>Scan Here</span>
                    </div>
                  </div>
                  <p >
                    <a  style={{fontSize:"12px"}}>www.canarabank.com</a><br />
                    <span style={{fontSize:"12px"}}>hocss1@canarabank.com</span>
                  </p>
                  <div style={{ height: "8px" }}></div>
                </div>
            </div>
          </div>
        </div>
        <footer className="footerBox footerBox1 p-0" style={{ background: 'none' }} >
        <div className="centered-fluid">
          <div className="centered">
            <div className="row">
              <div className="col-lg-12 footercol">
                <a href="#" className="call"><img src="images/call.png" alt="Call" style={{ marginRight: '10px' }} /> 9076030001</a>
                <ul>
                  <li><a href="#"><img src="images/fb.svg" alt="Facebook" width="24px" /></a></li>
                  <li><a href="#"><img src="images/insta.svg" alt="Instagram" width="24px" /></a></li>
                  <li><a href="#"><img src="images/linkedin.svg" alt="LinkedIn" width="24px" /></a></li>
                  <li><a href="#"><img src="images/youtube.svg" alt="YouTube" width="24px" /></a></li>
                </ul>
                <img src="images/bankNo.png" alt="Bank No" width="150px" />
              </div>
            </div>
          </div>
        </div>
      </footer>
      </section>

      {/* Download Button Section */}
      

      {/* Footer Section */}
      
    </div>
  );
};

export default QR;
