import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SelectLang from './SelectLang';
import useLanguage from '../languages/useLanguage';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EmployeeCode() {
    const langs = useLanguage();
    const lang = langs.employee;
    const navigate = useNavigate();
    const [employeeCode, setEmployeeCode] = useState('');
    const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;


    const handleSubmit = (event) => {
        event.preventDefault();
        
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (!employeeCode.trim()) {
            toast.warn(lang.error.error1);
            return;
        }

        if (code) {
            const apiUrl = API_ENDPOINT + '/api/getEmployeeDetails';

            const requestBody = {
                "stno": employeeCode
            };

            const headers = {
                'x-client-certificate': process.env.REACT_APP_CLIENT_CERTIFICATE,
                'x-signature': process.env.REACT_APP_SIGNATURE,
                'x-client-id' : process.env.REACT_APP_CLIENT_ID ,
                'x-client-secret': process.env.REACT_APP_CLIENT_SECRET  
            };
            console.log(headers);
            axios.post(apiUrl, requestBody, { headers })
                .then(response => {
                    const result = response.data.getStaffDetailsResponse.getStaffDetailsResult;
                    const details = result.split('|');
                    const extractedEmployeeCode = details[0]; // 700922
                    let extractedEmployeeName = details[9]; // MAMIDALA NITIN VYAS
                    extractedEmployeeName = extractedEmployeeName.replace(',', '');
                    // Save to local storage
                    localStorage.setItem('employeeCode', extractedEmployeeCode);
                    localStorage.setItem('employeeName', extractedEmployeeName);
                    navigate('/qr',{ state: { feedbackType : 'employee'} });

                    console.log('Employee Code:', extractedEmployeeCode);
                    console.log('Employee Name:', extractedEmployeeName);
                    // Handle the response data as needed
                })
                .catch(error => {
                    console.error('Error fetching employee details:', error);
                    toast.error(lang.error.error2);
                });

        } else {
            toast.error(lang.error.error3);
        }



    };    
    return (
        <>
            {/* Header Box Start */}
            <section className="headerBox blue-bg">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 pt-2 pb-2 top">
                                <a href="#">
                                    <img src="images/logo.png" alt="Canara Bank Logo" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid langCol">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 bottom pt-1 pb-1 top">
                <SelectLang />
              </div>
            </div>
          </div>
        </div>
            </section>
            {/* Header Box End */}

            {/* Employee Code Section Start */}
            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 mobileBox">
                                <div className="col-sm-6 left">
                                    <img src="images/Employee-Code.png" alt="Employee Code" />
                                </div>

                                <div className="col-sm-6 right">
                                    <div className="inner">
                                        <h1>{lang.line2}</h1>
                                        
                                        {/* <form onSubmit={handleSubmit}>
                                            <label htmlFor="mobile">{lang.line3}</label>
                                            <input type="text" className="mob" />
                                        </form>
                                        <a href="#" className="button">{lang.line4}</a> */}
                                        <form onSubmit={handleSubmit}>
                                            

                                        
                                            <input
                                                type="text"
                                                className="mob"
                                                id="employeeCode"
    placeholder ={lang.line1}
                                                value={employeeCode}
                                                onChange={(e) => setEmployeeCode(e.target.value)}
                                            />
                                            <button type="submit" className="button">{lang.line4}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Employee Code Section End */}

            {/* Footer Box Start */}
            <footer className="footerBox mt-3">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 p-0">
                                <p>@2024, Canara Bank, All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Box End */}
        </>
    );
}

export default EmployeeCode;
