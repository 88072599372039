const malayalam = {
    feedbackServiceFlow: {
        line1: "പൊതു പ്രതികരണം",
        line2: "സേവാ നിരപ്പിലെ പ്രതികരണം"
    },
    mobile: {
        line1: "നിങ്ങളുടെ മൊബൈൽ നമ്പർ നൽകുക",
        line2: "നാം നിങ്ങള്ക്ക് ഒരു പരിശോധന കോഡ് അയയ്ക്കും",
        line3: "മൊബൈൽ നമ്പർ നൽകുക",
        line4: "തുടരുക",
        error: {
            error: "ഒരു പിശക് സംഭവിച്ചു. ദയവായി ശേഷം വീണ്ടും ശ്രമിക്കുക",
            otp: "OTP അയയ്ക്കൽ പരാജയപ്പെട്ടു. ദയവായി വീണ്ടും ശ്രമിക്കുക."
        }
    },
    otp: {
        line1: "പരിശോധന കോഡ്",
        line2: "നാം നിങ്ങളുടെ മൊബൈൽ നമ്പറിലേക്ക് പരിശോധന കോഡ് അയച്ചു",
        line3: "OTP ലഭിച്ചില്ലേ?",
        line4: "തുടരുക",
        line5: "പ്രതികരണത്തിനായി സേവാ തരം തിരഞ്ഞെടുക്കുക",
        line6: "പണം നിക്ഷേപം",
        line7: "ഫണ്ട് ട്രാൻസ്ഫർ",
        line8: "അക്കൗണ്ട് തുറക്കൽ",
        line9: "SB അക്കൗണ്ട് ബന്ധമുള്ള സേവനങ്ങൾ",
        line10: "നിലവിലുള്ള അക്കൗണ്ട്",
        line11: "റദ്ദാക്കുക",
        line12: "അടുത്തത്",
        line13: "OTP വീണ്ടും അയയ്ക്കുക",
        line14: "OTP വിജയകരമായി അയച്ചു",
        noTransactionTypes: "എന്തെങ്കിലും സേവനം ഉപയോഗിക്കാതെ നിങ്ങൾക്ക് പ്രതികരണം സമർപ്പിക്കാൻ കഴിയില്ല",
        error: {
            error: "OTP അയയ്ക്കുമ്പോൾ പിശക് സംഭവിച്ചു. ദയവായി ശേഷം വീണ്ടും ശ്രമിക്കുക",
            otp: "അസാധുവായ OTP"
        }
    },
    feedback: {
        line1: "ഇന്ന് ഞങ്ങളുടെ അടുത്തുണ്ടായ അനുഭവത്തിന് നിങ്ങൾ എത്രമാത്രം വിലയിരുത്തുന്നു",
        verypoor: "വളരെ മോശം",
        belowAverage: "സാധാരണമേക്കാൾ താഴെ",
        average: "സാധാരണം",
        good: "നല്ലത്",
        veryGood: "വളരെ നല്ലത്",
        excellent: "മികച്ചത്",
        outstanding: "അദ്ഭുതകരം",
        poorFeedbackHeader: "നമുക്ക് ക്ഷമയില്ല, ദയവായി അസന്തോഷത്തിന്റെ കാരണം വിശദീകരിക്കുക:",
        badService: "മോശം സേവനം",
        notGoodSupport: "ഉപഭോക്തൃ പിന്തുണ നല്ലതല്ല",
        others: "മറ്റുള്ളവ",
        textareaPlaceholder: "നമുക്ക് ക്ഷമയില്ല",
        captchaPlaceholder: "ക്യാപ്ച്ച നൽകുക",
        reload: "ക്യാപ്ച്ച റീലോഡ് ചെയ്യുക",
        submit: "സമർപ്പിക്കുക",
        work: "പണി സമയത്ത് പൂർത്തിയാക്കാനായില്ല",
        inadequate: "ഉൽപ്പന്നം/സേവനത്തിൽ സാങ്കേതിക വിദ്യയിൽ സ്റ്റാഫിന് പര്യാപ്തം അല്ലാത്ത അറിവ്",
        guidance: "പണി ചെയ്യാൻ വേണ്ടത് ശരിയായ മാർഗ്ഗനിർദേശങ്ങൾ നൽകിയില്ല",
        staff: "സ്റ്റാഫിന്റെ പെരുമാറ്റം",
        products: "ഉൽപ്പന്നങ്ങൾ/പ്രക്രിയകൾ ഉപഭോക്താക്കളുടെ ആവശ്യങ്ങൾ നിറവേറ്റുന്നതിനായി യോഗ്യമല്ല",
        other: "മറ്റേതെങ്കിലും",
        general1: "പ്രവർത്തി സംതൃപ്തികരമായി പൂർത്തിയാക്കിയിട്ടില്ല",
general2: "ഉൽപ്പന്നം/സേവനം സംബന്ധിച്ച സ്റ്റാഫിന്റെ പര്യാപ്ത അറിവില്ല",
general3: "പ്രവർത്തി പൂർത്തിയാക്കുന്നതിനുള്ള യുക്തമായ മാർഗനിർദ്ദേശം നൽകിയിട്ടില്ല",
general4: "സ്റ്റാഫിന്റെ പെരുമാറ്റം",
general5: "ഉൽപ്പന്നങ്ങൾ/പ്രക്രിയകൾ ഉപഭോക്തൃ ആവശ്യങ്ങൾ നിറവേറ്റാൻ അനുയോജ്യമല്ല",
general6: "മറ്റേതെങ്കിലും",
        error: {
            selectRating: "ദയവായി ഒരു റേറ്റിംഗ് തിരഞ്ഞെടുക്കുക.",
            incorrectCaptcha: "ക്യാപ്ച്ച തെറ്റാണ്.",
            submitFeedback: "പ്രാമാണീകരണം കൂടാതെ നിങ്ങൾക്ക് അഭിപ്രായം നൽകാൻ സാധിക്കില്ല",
            twice: "നിങ്ങൾ രണ്ടുതവണ പ്രതികരണം നൽകാൻ കഴിയില്ല",
            transaction: "ലാവാദേവി നടത്തുന്നതിന് മുമ്പ് നിങ്ങൾക്ക് അഭിപ്രായം നൽകാൻ കഴിയില്ല"
        }
    },
    thankYou: {
        line1: "പ്രതികരണത്തിന് നന്ദി,",
        line2: "നാം നിങ്ങളുടെ മറുപടിക്ക് വില കൊടുക്കുന്നു"
    },
    feedbackMessage: {
        line1: "നാം ഇതിനകം നിങ്ങളുടെ പ്രതികരണം സ്വീകരിച്ചിട്ടുണ്ട്",
        line2: "നിങ്ങൾക്ക് രണ്ട് തവണ സമർപ്പിക്കാൻ കഴിയില്ല"
    },
    employee: {
        line1: "തൊഴിലാളി കോഡ് നൽകുക",
        line2: "ദയവായി നിങ്ങളുടെ തൊഴിലാളി കോഡ് നൽകുക",
        line3: "തൊഴിലാളി കോഡ്",
        line4: "സമർപ്പിക്കുക",
        error: {
            error1: "തൊഴിലാളി കോഡ് ശൂന്യമായിരിക്കരുത്",
            error2: "ഈ ഐഡി ഉപയോഗിച്ച് ജോലിയുണ്ടായിട്ടില്ല",
            error3: "ലോഗിൻ വിശദാംശങ്ങൾ പ്രാപിച്ചില്ല"
        }
    },
    salesforce: {
        line1: "സെയിൽസ്‌ഫോഴ്സുമായി ലോഗിൻ ചെയ്യുക",
        line2: "സെയിൽസ്‌ഫോഴ്സുമായി ലോഗിൻ ചെയ്യുക"
    },
    qr : "പ്രിയ ഉപഭോക്താക്കളേ, നിങ്ങളുടെ ഫീഡ്ബാക്ക് പ്രധാനമാണ്! നിങ്ങൾക്ക് മികച്ച സേവനം നൽകാൻ ഞങ്ങളെ സഹായിക്കുന്നതിന് ബ്രാഞ്ച് ബാങ്കിംഗ് അനുഭവത്തെക്കുറിച്ചുള്ള നിങ്ങളുടെ ഫീഡ്‌ബാക്ക് ദയവായി സമർപ്പിക്കുക"
}

export default malayalam;
