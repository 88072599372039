const telugu = {
    feedbackServiceFlow: {
        line1: "సాధారణ అభిప్రాయం",
        line2: "సేవా స్థాయి అభిప్రాయం"
    },
    mobile: {
        line1: "మీ మొబైల్ నంబర్ నమోదు చేయండి",
        line2: "మేము మీకు ఒక ధృవీకరణ కోడ్ పంపుతాము",
        line3: "మొబైల్ నంబర్ నమోదు చేయండి",
        line4: "కొనసాగించు",
        error: {
            error: "ఒక లోపం జరిగింది. దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి",
            otp: "OTP పంపడం విఫలమైంది. దయచేసి మళ్లీ ప్రయత్నించండి."
        }
    },
    otp: {
        line1: "ధృవీకరణ కోడ్",
        line2: "మేము మీ మొబైల్ నంబర్‌కు ధృవీకరణ కోడ్ పంపాము",
        line3: "OTP అందలేదా?",
        line4: "కొనసాగించు",
        line5: "అభిప్రాయానికి సేవ రకం ఎంచుకోండి",
        line6: "నగదు జమ",
        line7: "ఫండ్ ట్రాన్స్‌ఫర్",
        line8: "ఖాతా ప్రారంభం",
        line9: "SB ఖాతా సంబంధిత సేవలు",
        line10: "ప్రస్తుత ఖాతా",
        line11: "రద్దు చేయి",
        line12: "తదుపరి",
        line13: "OTP మళ్లీ పంపండి",
        line14: "OTP విజయవంతంగా పంపబడింది",
        noTransactionTypes: "ఏ సేవను ఉపయోగించకుండా మీరు అభిప్రాయాన్ని సమర్పించలేరు",
        error: {
            error: "OTP పంపే సమయంలో లోపం. దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి",
            otp: "చెల్లని OTP"
        }
    },
    feedback: {
        line1: "ఈరోజు మా వద్ద మీ అనుభవాన్ని రేట్ చేయండి",
        verypoor: "చాలా చెత్త",
        poor: "చెత్త",
        average: "సగటు",
        good: "మంచి",
        veryGood: "చాలా మంచి",
        outstanding: "అద్భుతం",
        poorFeedbackHeader: "మేము అసంతృప్తి వ్యక్తం చేస్తున్నాము, దయచేసి అసంతృప్తి కారణాన్ని వివరించండి:",
        badService: "చెత్త సేవ",
        notGoodSupport: "వినియోగదారు మద్దతు సరైనది కాదు",
        others: "ఇతర",
        textareaPlaceholder: "మేము అసౌకర్యానికి చింతిస్తున్నాము",
        captchaPlaceholder: "క్యాప్చా నమోదు చేయండి",
        reload: "క్యాప్చా రీలోడ్ చేయండి",
        submit: "సమర్పించు",
        work: "పని సమయానికి పూర్తికాలేదు",
        inadequate: "ఉత్పత్తుల/సేవలపై సిబ్బంది సరైన పరిజ్ఞానం లేదు",
        guidance: "పని చేయడానికి సరైన మార్గనిర్దేశం ఇవ్వబడలేదు",
        staff: "సిబ్బంది ప్రవర్తన",
        products: "ఉత్పత్తులు/ప్రక్రియలు వినియోగదారుల అవసరాలను తీర్చడానికి సరిపోవు",
        other: "ఇతర ఏదైనా",
        general1: "పని సంతృప్తికరంగా పూర్తి కాలేదు",
general2: "ఉత్పత్తి/సేవపై సిబ్బంది సరైన అవగాహన లేనిది",
general3: "పని చేయడానికి సరైన మార్గదర్శకత్వం ఇవ్వలేదు",
general4: "సిబ్బంది ప్రవర్తన",
general5: "ఉత్పత్తులు/ప్రక్రియలు వినియోగదారుల అవసరాలను తీర్చడంలో సరైనవి కావు",
general6: "మరేదైనా",
        error: {
            selectRating: "దయచేసి రేటింగ్‌ను ఎంచుకోండి.",
            incorrectCaptcha: "క్యాప్చా తప్పు.",
            submitFeedback: "ధృవీకరణ లేకుండా మీరు అభిప్రాయాన్ని ఇవ్వలేరు",
            twice: "మీరు రెండవ సారి అభిప్రాయం ఇవ్వలేరు",
            transaction: "మీరు ఏదైనా లావాదేవీ చేసే ముందు అభిప్రాయాన్ని ఇవ్వలేరు"
        }
    },
    thankYou: {
        line1: "అభిప్రాయం కోసం ధన్యవాదాలు,",
        line2: "మేము మీ ప్రతిస్పందనను మన్నిస్తాము"
    },
    feedbackMessage: {
        line1: "మేము మీ అభిప్రాయాన్ని ఇప్పటికే స్వీకరించాము",
        line2: "మీరు రెండవ సారి సమర్పించలేరు"
    },
    employee: {
        line1: "ఉద్యోగి కోడ్ నమోదు చేయండి",
        line2: "దయచేసి మీ ఉద్యోగి కోడ్‌ను నమోదు చేయండి",
        line3: "ఉద్యోగి కోడ్",
        line4: "సమర్పించు",
        error: {
            error1: "ఉద్యోగి కోడ్ ఖాళీగా ఉండకూడదు",
            error2: "ఈ ఐడితో ఉద్యోగిని కనుగొనలేము",
            error3: "లాగిన్ వివరాలను పొందలేము"
        }
    },
    salesforce: {
        line1: "సేల్స్‌ఫోర్స్‌తో లాగిన్ అవ్వండి",
        line2: "సేల్స్‌ఫోర్స్‌తో లాగిన్ అవ్వండి"
    },
    qr : "ప్రియమైన కస్టమర్లారా, మీ అభిప్రాయం ముఖ్యం! మీకు మెరుగైన సేవలందించడంలో మాకు సహాయం చేయడానికి బ్రాంచ్ బ్యాంకింగ్ అనుభవంపై మీ అభిప్రాయాన్ని దయచేసి సమర్పించండి"
}

export default telugu;
