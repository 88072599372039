const bengali = {
    feedbackServiceFlow: {
        line1: "সাধারণ প্রতিক্রিয়া",
        line2: "সেবা স্তরের প্রতিক্রিয়া"
    },
    mobile: {
        line1: "আপনার মোবাইল নম্বর দিন",
        line2: "আমরা আপনাকে একটি যাচাইকরণ কোড পাঠাবো",
        line3: "মোবাইল নম্বর দিন",
        line4: "চালিয়ে যান",
        error: {
            error: "একটি ত্রুটি ঘটেছে। দয়া করে পরে আবার চেষ্টা করুন",
            otp: "OTP পাঠাতে ব্যর্থ। দয়া করে আবার চেষ্টা করুন।"
        }
    },
    otp: {
        line1: "যাচাইকরণ কোড",
        line2: "আমরা আপনার মোবাইল নম্বরে যাচাইকরণ কোড পাঠিয়েছি",
        line3: "OTP পাচ্ছেন না?",
        line4: "চালিয়ে যান",
        line5: "ফিডব্যাকের জন্য সেবা প্রকার নির্বাচন করুন",
        line6: "নগদ জমা",
        line7: "ফান্ড ট্রান্সফার",
        line8: "অ্যাকাউন্ট খোলা",
        line9: "SB অ্যাকাউন্ট সম্পর্কিত সেবাসমূহ",
        line10: "কারেন্ট অ্যাকাউন্ট",
        line11: "বাতিল",
        line12: "পরবর্তী",
        line13: "OTP পুনরায় পাঠান",
        line14: "OTP সফলভাবে পাঠানো হয়েছে",
        noTransactionTypes: "কোনো সেবা ব্যবহার না করে আপনি ফিডব্যাক প্রদান করতে পারবেন না",
        error: {
            error: "OTP পাঠাতে ত্রুটি ঘটেছে। দয়া করে পরে আবার চেষ্টা করুন",
            otp: "অবৈধ OTP"
        }
    },
    feedback: {
        line1: "আজ আমাদের সাথে আপনার অভিজ্ঞতার মূল্যায়ন করুন",
        verypoor: "অত্যন্ত খারাপ",
        belowAverage: "গড়ের নিচে",
        average: "গড়",
        good: "ভাল",
        veryGood: "খুব ভাল",
        excellent: "চমৎকার",
        outstanding: "অত্যন্ত শ্রেষ্ঠ",
        poorFeedbackHeader: "আমরা দুঃখিত, দয়া করে অস্বস্তির কারণ বিস্তারিতভাবে উল্লেখ করুন:",
        badService: "খারাপ সেবা",
        notGoodSupport: "গ্রাহক সহায়তা ভাল নয়",
        others: "অন্যান্য",
        textareaPlaceholder: "আমরা দুঃখিত",
        captchaPlaceholder: "ক্যাপচা লিখুন",
        reload: "ক্যাপচা পুনরায় লোড করুন",
        submit: "জমা দিন",
        work: "কাজ সময়মতো সম্পন্ন হয়নি",
        inadequate: "স্টাফের পণ্যের/সেবার উপর অপ্রতুল জ্ঞান",
        guidance: "কাজ সম্পন্ন করার জন্য সঠিক দিকনির্দেশনা দেওয়া হয়নি",
        staff: "স্টাফের আচরণ",
        products: "পণ্য/প্রক্রিয়া গ্রাহকদের প্রয়োজন মেটাতে উপযুক্ত নয়",
        other: "অন্যান্য",
        general1: "কাজ সন্তোষজনকভাবে সম্পন্ন হয়নি",
general2: "পণ্য/পরিসেবা সম্পর্কে কর্মীদের পর্যাপ্ত জ্ঞান নেই",
general3: "কাজ করার জন্য সঠিক দিকনির্দেশনা দেওয়া হয়নি",
general4: "কর্মীদের আচরণ",
general5: "পণ্য/প্রক্রিয়া গ্রাহকদের প্রয়োজন মেটাতে উপযুক্ত নয়",
general6: "অন্যান্য কিছু",
        error: {
            selectRating: "দয়া করে একটি রেটিং নির্বাচন করুন।",
            incorrectCaptcha: "ক্যাপচা ভুল।",
            submitFeedback: "অর্থপ্রদান ছাড়া আপনি প্রতিক্রিয়া দিতে পারবেন না",
            twice: "আপনি দুইবার প্রতিক্রিয়া দিতে পারবেন না",
            transaction: "কোনো লেনদেন ছাড়া আপনি প্রতিক্রিয়া দিতে পারবেন না"
        }
    },
    thankYou: {
        line1: "ফিডব্যাক দেওয়ার জন্য ধন্যবাদ,",
        line2: "আমরা আপনার প্রতিক্রিয়া মূল্যায়ন করি"
    },
    feedbackMessage: {
        line1: "আমরা ইতিমধ্যেই আপনার ফিডব্যাক গ্রহণ করেছি",
        line2: "আপনি দুইবার জমা দিতে পারবেন না"
    },
    qr:"প্রিয় গ্রাহক, আপনার মতামত গুরুত্বপূর্ণ! আমাদের আপনাকে আরও ভাল পরিষেবা দিতে সাহায্য করার জন্য অনুগ্রহ করে শাখা ব্যাঙ্কিং অভিজ্ঞতার বিষয়ে আপনার প্রতিক্রিয়া জমা দিন।"
};

export default bengali;
