const english = {
    feedbackServiceFlow : {
        line1 : "General Feedback",
        line2 : "Service Level Feedback"
    },
    mobile : {
        line1 : "Enter Your Mobile Number",
        line2 : "We will send you a Verification Code",
        line3 : "Enter Mobile No.",
        line4 : "Continue",
        error :{
            error : "An error occurred. Please try again later",
            otp : "Failed to send OTP. Please try again."
        }
    },
    otp : {
        line1 : "Verification Code",
        line2 : "We have sent the verification code to your Mobile Number",
        line3 : "Don't receive the OTP?",
        line4 : "Continue",
        line5 : "Please Select Service Type For Feedback",
        line6 : "Cash Deposit" ,
        line7 : "Fund Transfer",
        line8 : "Account Opening",
        line9 : "SB Account related Services",
        line10 : "Current Account",
        line11 : "Cancel",
        line12 : "Next",
        line13 : "Resend OTP",
        line14 : "OTP sent successfully",
        noTransactionTypes : "You cannot submit feedback without using any service",
        error :{
            error : "Error while sending OTP. Please try again later",
            otp : "Invalid OTP"
        }
    },
    feedback : {
        line1 : "Please rate your experience with us today",
        verypoor : "Very Poor",
        belowAverage : "Below Average",
        average : "Average",
        good : "Good",
        veryGood : "Very Good",
        excellent : "Excellent",
        poorFeedbackHeader : "We regret Inconvenience, please detail the reason for the dissatisfaction:",
        badService : "Bad Service",
        notGoodSupport : "Customer Support Not Good",
        others : "Others",
        textareaPlaceholder : "We regret inconvenience",
        captchaPlaceholder : "Enter Captcha",
        reload : "Reload Captcha",
        submit : "Submit",
        work : "Work not completed on time",
        inadequate : "Inadequate knowledge of staff on product/service",
        guidance : "No proper guidance given to get the work done",
        staff : "Staff behavior",
        products : "Products/Process is not apt to fulfill the needs of the customers",
        other : "Any other",
        general1: "Work not completed Satisfactory",
        general2: "Inadequate knowledge of staff on product/service",
        general3: "No proper guidance given to get the work done",
        general4 : "Staff behavior",
        general5 : "Products/Process is not apt to fulfill the needs of the customers",
        general6 : "Any other",
        error : {
            selectRating : "Please select a rating.",
            incorrectCaptcha : "Captcha is incorrect.",
            submitFeedback : "You cannot give the feedback without authentication",
            twice : "You cannot give the feedback twice",
            transaction : "You cannot give the feedback before making any transaction"
        }
    },
    thankYou : {
        line1 : "Thank You for the Feedback,",
        line2 : "We value your Response"
    },
    feedbackMessage : {
        line1 : "We've already received your feedback",
        line2 : "You cannot submit twice"
    },
    feedbackMessageService : {
        line1 : "Sorry,",
        line2 : "You cannot submit service feedback without using any service"
    },
    employee : {
        line1 : "Enter Employee Code",
        line2 : "Please enter your employee code",
        line3 : "Employee Code",
        line4 : "Submit",
        error : {
            error1 : "Employee Code cannot be empty",
            error2 : "Unable to find employee with this id",
            error3 : "Unable to fetch Login Details"
        }
    },
    salesforce : {
        line1 : "Login with Salesforce",
        line2 : "Login with Salesforce"
    },
    qr:"प्रिय ग्राहक, आपका सुझाव हमारे लिए महत्वपूर्ण है। कृपया शाखा में अपने बैंकिंग अनुभव पर प्रतिक्रिया दे ताकि हम आपको बेहतर सेवा दे सके।"
}

export default english;
