const odiya = {
    feedbackServiceFlow: {
        line1: "ସାଧାରଣ ପ୍ରତିକ୍ରିୟା",
        line2: "ସେବା ସ୍ତର ପ୍ରତିକ୍ରିୟା"
    },
    mobile: {
        line1: "ଆପଣଙ୍କର ମୋବାଇଲ୍ ନମ୍ବର ଦିଅନ୍ତୁ",
        line2: "ଆମେ ଆପଣଙ୍କୁ ଗୋଟିଏ ଭେରିଫିକେସନ୍ କୋଡ୍ ପଠାଉ",
        line3: "ମୋବାଇଲ୍ ନମ୍ବର ଦିଅନ୍ତୁ",
        line4: "ଚାଲୁ ରଖନ୍ତୁ",
        error: {
            error: "ଏକ ତ୍ରୁଟି ଘଟିଛି। ଦୟାକରି ପରେ ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ",
            otp: "OTP ପଠାଇବାରେ ବିଫଳ। ଦୟାକରି ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ।"
        }
    },
    otp: {
        line1: "ଭେରିଫିକେସନ୍ କୋଡ୍",
        line2: "ଆମେ ଆପଣଙ୍କର ମୋବାଇଲ୍ ନମ୍ବରକୁ ଭେରିଫିକେସନ୍ କୋଡ୍ ପଠାଇଛୁ",
        line3: "OTP ପାଉନାହାଁନ୍ତି କି?",
        line4: "ଚାଲୁ ରଖନ୍ତୁ",
        line5: "ପ୍ରତିକ୍ରିୟା ପାଇଁ ସେବା ପ୍ରକାର ବାଛନ୍ତୁ",
        line6: "ନକଦ ଜମା",
        line7: "ଧନ ରାହି",
        line8: "ଖାତା ଖୋଲିବା",
        line9: "SB ଖାତା ସମ୍ବନ୍ଧୀୟ ସେବାସମୂହ",
        line10: "ଚାଲୁ ଖାତା",
        line11: "ବାତିଲ୍",
        line12: "ପରବର୍ତ୍ତୀ",
        line13: "OTP ପୁନର୍ବାର ପଠାନ୍ତୁ",
        line14: "OTP ସଫଳତାର ସହ ଅନ୍ୟାନ୍ୟ କୋଡ୍ ପଠାଇଛି",
        noTransactionTypes: "କୌଣସି ସେବା ବ୍ୟବହାର କରିବା ବିନା ଆପଣ ପ୍ରତିକ୍ରିୟା ପ୍ରଦାନ କରିପାରିବେ ନାହିଁ",
        error: {
            error: "OTP ପଠାଇବାରେ ତ୍ରୁଟି ହେଲା। ଦୟାକରି ପରେ ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ",
            otp: "ଅବୈଧ OTP"
        }
    },
    feedback: {
        line1: "ଆଜି ଆମ ସହିତ ଆପଣଙ୍କ ଅନୁଭବକୁ ମୂଲ୍ୟାୟନ କରନ୍ତୁ",
        verypoor: "ଅତି ଖରାପ",
        belowAverage: "ସାଧାରଣ ଠାରୁ କମ୍",
        average: "ସାଧାରଣ",
        good: "ଭଲ",
        veryGood: "ଅତି ଭଲ",
        excellent: "ଉତ୍କୃଷ୍ଟ",
        outstanding: "ଅତି ଉତ୍କୃଷ୍ଟ",
        poorFeedbackHeader: "ଆମେ ଦୁଃଖିତ, ଦୟାକରି ଅସନ୍ତୋଷର କାରଣକୁ ବିବରଣୀ କରନ୍ତୁ:",
        badService: "ଖରାପ ସେବା",
        notGoodSupport: "ଗ୍ରାହକ ସହାୟତା ଭଲ ନାହିଁ",
        others: "ଅନ୍ୟାନ୍ୟ",
        textareaPlaceholder: "ଆମେ ଦୁଃଖିତ",
        captchaPlaceholder: "କ୍ୟାପ୍ଚା ଲେଖନ୍ତୁ",
        reload: "କ୍ୟାପ୍ଚା ପୁନର୍ଲୋଡ୍ କରନ୍ତୁ",
        submit: "ଦାଖଲ କରନ୍ତୁ",
        work: "କାମ ସମୟରେ ସମାପ୍ତ ହେଲା ନାହିଁ",
        inadequate: "ସ୍ତାଫର ପ୍ରତିଜ୍ଞା / ସେବା ବାବଦରେ ଜ୍ଞାନ ତ୍ରୁଟି",
        guidance: "କାମ କରାଇବାକୁ ସଠିକ୍ ଦିଗବିବରଣ ଦିଆଯାଇ ନାହିଁ",
        staff: "ସ୍ତାଫର ବ୍ୟବହାର",
        products: "ପ୍ରଦର୍ଶିତ / ପ୍ରକ୍ରିୟା ଗ୍ରାହକମାନଙ୍କ ଆବଶ୍ୟକତା ପୂରଣ କରିବା ପାଇଁ ଆପ୍ତ ନୁହେଁ",
        other: "ଅନ୍ୟାନ୍ୟ",
        general1: "କାମ ସନ୍ତୋଷଜନକ ଭାବେ ସମାପ୍ତ ହୋଇନାହିଁ",
general2: "ଉତ୍ପାଦ/ସେବା ବିଷୟରେ କର୍ମଚାରୀଙ୍କର ପର୍ଯ୍ୟାପ୍ତ ଜ୍ଞାନ ନାହିଁ",
general3: "କାମକୁ ସଫଳ କରିବା ପାଇଁ ଠିକ ମାର୍ଗଦର୍ଶନ ଦିଆଯାଇନାହିଁ",
general4: "କର୍ମଚାରୀଙ୍କର ବ୍ୟବହାର",
general5: "ଉତ୍ପାଦ/ପ୍ରକ୍ରିୟା ଗ୍ରାହକଙ୍କ ଆବଶ୍ୟକତା ପୂରଣ କରିବା ପାଇଁ ଯୋଗ୍ୟ ନୁହେଁ",
general6: "ଅନ୍ୟ କିଛି",
        error: {
            selectRating: "ଦୟାକରି ମୂଲ୍ୟାୟନ ବାଛନ୍ତୁ।",
            incorrectCaptcha: "କ୍ୟାପ୍ଚା ତ୍ରୁଟି ଅଛି।",
            submitFeedback: "ଅନୁମତି ବିନା ଆପଣ ପ୍ରତିକ୍ରିୟା ପ୍ରଦାନ କରିପାରିବେ ନାହିଁ",
            twice: "ଦୁଇଥର ଆପଣ ପ୍ରତିକ୍ରିୟା ପ୍ରଦାନ କରିପାରିବେ ନାହିଁ",
            transaction: "କୌଣସି ଚାଲିଲା ବିନା ପ୍ରତିକ୍ରିୟା ପ୍ରଦାନ କରିବା ଆପଣ ଅନୁମତି ନାହିଁ"
        }
    },
    thankYou: {
        line1: "ପ୍ରତିକ୍ରିୟା ପାଇଁ ଧନ୍ୟବାଦ,",
        line2: "ଆମେ ଆପଣଙ୍କର ଉତ୍ତରକୁ ମୂଲ୍ୟାୟନ କରିବା"
    },
    feedbackMessage: {
        line1: "ଆମେ ଏବେ ପର୍ଯ୍ୟନ୍ତ ଆପଣଙ୍କର ପ୍ରତିକ୍ରିୟା ପାଇଛି",
        line2: "ଆପଣ ଦୁଇଥର ପରେ ଅର୍ପଣ କରିପାରିବେ ନାହିଁ"
    },
    qr :"ପ୍ରିୟ ଗ୍ରାହକ, ଆପଣଙ୍କର ମତାମତ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ! ଆମକୁ ଭଲ ଭାବରେ ସେବା କରିବାରେ ସାହାଯ୍ୟ କରିବାକୁ ଶାଖା ବ୍ୟାଙ୍କିଙ୍ଗ ଅଭିଜ୍ଞତା ଉପରେ ଦୟାକରି ଆପଣଙ୍କର ମତାମତ ଦାଖଲ କରନ୍ତୁ"
};

export default odiya;
